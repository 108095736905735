import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'

function SEO ({ description, metaTags, lang, title, metaImage, type }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            keywords
            image
          }
        }
      }
    `
  )

  const location = useLocation()
  const metaDescription = description || site.siteMetadata.description

  const image =
    typeof metaImage === 'object'
      ? metaImage.src
      : metaImage || site.siteMetadata.image

  const url = `${site.siteMetadata.siteUrl}${location.pathname}`

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={'%s'}
    >
      <meta name="description" content={metaDescription} />
      {/* open graph */}
      <meta property="og:type" content={type || 'website'} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title || site.siteMetadata.title} />
      <meta property="og:description" content={metaDescription} />
      <meta
        property="og:image"
        content={`${site.siteMetadata.siteUrl}${image}`}
      />

      {/* twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={metaDescription} />
      <meta
        property="twitter:image"
        content={`${site.siteMetadata.siteUrl}${image}`}
      />

      <meta
        name="keywords"
        content={`
        Explorelogy,
        explorelogy solutions,
        Explore,
        Innovate,
        Accelerate ,
        IT,
        IT companies in sri lanka,
        IT solutions in sri lanka,
        IT solution companies in sri lanka,
        IT services,
        IT services in sri lanka ,
        Software,
        Software systems in sri lanka,
        Software companies in Sri Lanka,
        Best software companies in sri lanka,
        software companies in sri lanka ,
        Solution,
        Software solutions,
        Software solutions in sri lanka,
        Website,
        Website development in Sri Lanka,
        Website development companies in Sri Lanka,
        Web development,
        Web development companies,
        Website development companies,
        What is web development,
        Wordpress,
        Wordpress development in sri lanka,
        Web developers in sri lanka,
        Wordpress developer is sri lanka,
        Responsive website development,
        Responsive web development,
        Website design,
        Web design,
        Web design companies,
        Website design companies ,
        Web Design companies in sri lanka,
        Responsive web design companies in sri lanka,
        Cms,
        Cms development ,
        Cms development companies,
        Cms development companies in sri lanka,
        Cms development in sri lanka,
        Crm,
        Crm development in sri lanka,
        Digital marketing,
        Digital marketing companies,
        Digital marketing companies in sri lanka,
        Marketing,
        Ecommerce ,
        Ecommerce development in sri lanka,
        ecommerce development companies in sri lanka,
        Mobile,
        Application,
        Mobile apps,
        Mobile application,
        Mobile app development,
        Mobile application development, 
        Mobile app development companies,
        Mobile app development companies in sri lanka,
        Mobile app development in sri lanka,
        Best mobile development companies in sri lanka,
        Android app development,
        Android application development,
        Iphone app development,
        Iphone application development,
        Ios app development,
        Ios application development,
        Huawei app development,
        Huawei application development,
        HarmonyOS app development,
        HarmonyOS application development,
        HarmonyOS app development in sri lanka,
        Harmony,
        Android,
        IOS,
        Responsive mobile development,
        Responsive mobile app development,
        Responsive mobile application development,
        ui,
        ux,
        ui ux,
        User interface,
        Design,
        Ui design,
        Mobile app design,
        Seo,
        Seo companies in sri lanka,
        Best seo companies in sri lanka,
        Sem companies in sri lanka,
        Seo development in sri lanka,
        Seo in Sri Lanka,
        On page development in sri lanka,
        On page development companies in sri lanka,
        Off page development in sri lanka,
        Off page development companies in sri lanka,
        Website optimization,
        Youtube optimization,
        Rank,
        Website rank up,
        Business concept development,
        Business concept development in sri lanka,
        Business concept development companies in sri lanka,
        What is Business concept development,
        Cloud services ,
        Managed cloud services ,
        Managed cloud services in sri lanka,
        What is Managed cloud service,
        Cloud migration services,
        Cloud migration services in sri lanka,
        Cloud infrastructure and implementation services,
        Cloud infrastructure and implementation services in sri lanka,
        next generation enterprise cloud security services,
        next generation enterprise cloud security services in sri lanka,
        Cloud deployment and integration ,
        Cloud deployment and integration in sri lanka,
        Cloud orchestration,
        Cloud orchestration in sri lanka,
        What is managed cloud service,
        Managed IT services ,
        Managed IT services in sri lanka,
        Cyber security,
        Cyber security in sri lanka,
        What is Cyber security,
        IT consultancy ,
        IT consultancy in sri lanka,
        What is IT consultancy, 
        Erp,
        Erp development in sri lanka,
        Erp development companies in sri lanka,
        Enterprise resource planning,
        Enterprise resource planning in Sri Lanka,
        Enterprise resource planning companies in Sri Lanka,
        Enterprise resource planning solution,
        Erp solution in Sri Lanka,
        Erp companies ,
        Eduplus,
        Eduplus system,
        Revision,
        Revision live,
        network,
        portal,
        business,
        online,
        more,
        ${metaTags}
        `}
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: 'Explorelogy is a pioneering IT services provider, advocating for aim based technology solutions for businesses. Our mission is to build customised, long-term solutions catering to company specific needs, that enable you to drive forward your business with ease, efficiency, and safety.'
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  })
}

export default SEO
